import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';

const InitMetricsMixin = {
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
            dateRange: (state) => state.metrics.dateRange,
            interval: (state) => state.metrics.interval,
            fbCampaignsSelected: (state) =>
                state.metrics.facebook.ads.campaignsSelected,
        }),
    },
    watch: {
        currentDealerId(dealerId, oldDealerId) {
            if (dealerId !== null && dealerId != oldDealerId) {
                this.loadDealerData();
            }
        },
        dateRange(newRange, oldRange) {
            // Only load metrics if the date range has changed
            if (
                newRange.startDate !== oldRange.startDate ||
                newRange.endDate !== oldRange.endDate
            ) {
                this.loadMetrics();
            }
        },
        fbCampaignsSelected() {
            this.startFbInsightsLoaders();
            this.loadMetricsDebounced();
        },
        interval() {
            this.loadIntervalMetrics();
        },
    },
    async created() {
        await this.waitForDealerLoaded();

        this.loadDealerData();
    },
    methods: {
        ...mapActions(['waitForDealerLoaded']),
        ...mapActions('metrics/facebook/ads', {
            startFbAdsDataLoaders: 'startDataLoaders',
            startFbInsightsLoaders: 'startMetricsLoaders',
            updateDealerFbInsights: 'updateAllMetrics',
            updateDealerFbInsightsIntervals: 'updateMetricsIntervals',
            updateLastEventUploadDate: 'updateLastEventUploadDate',
            updateDealerFbCampaigns: 'updateCampaigns',
        }),

        ...mapActions('metrics/snapchat/ads', {
            updateDealerScCampaigns: 'updateCampaigns',
            startScMetricLoaders: 'startMetricsLoaders',
            updateDealerScMetrics: 'updateAllMetrics',
        }),
        ...mapActions('metrics/tiktok/ads', {
            updateDealerTiktokCampaigns: 'updateCampaigns',
            startTtMetricLoaders: 'startMetricsLoaders',
            updateDealerTtMetrics: 'updateAllMetrics',
        }),
        ...mapActions('metrics/pinterest/ads', {
            updateDealerPinterestCampaigns: 'updateCampaigns',
            startPinMetricLoaders: 'startMetricsLoaders',
            updateDealerPinMetrics: 'updateAllMetrics',
        }),
        async loadDealerData() {
            this.startFbAdsDataLoaders();
            this.startFbInsightsLoaders();
            this.startScMetricLoaders();
            this.startTtMetricLoaders();
            this.startPinMetricLoaders();

            await this.waitForDealerLoaded();

            // Ensure the store is updated
            await this.$nextTick();

            // Load Facebook Ads data
            this.updateLastEventUploadDate();
            this.updateDealerFbCampaigns();

            // Load Snapchat Campaigns data
            this.updateDealerScCampaigns();

            // Load Tiktok Campaigns data
            this.updateDealerTiktokCampaigns();

            // Load Pinterest Campaigns data
            this.updateDealerPinterestCampaigns();

            // Trigger the central metrics loader
            this.loadMetrics();
        },
        loadMetrics() {
            this.updateDealerFbInsights();
            this.updateLastEventUploadDate();
            this.updateDealerScMetrics();
            this.updateDealerTtMetrics();
            this.updateDealerPinMetrics();
        },
        loadMetricsDebounced: debounce(function() {
            this.loadMetrics();
        }, 500),
        loadIntervalMetrics() {
            this.updateDealerFbInsightsIntervals();
        },
    },
};

export default InitMetricsMixin;
