import HttpClient from '@/http';

const getLastEventUploadDate = async({ dealerId }) => {
    const params = {
        fields: ['event_stats', 'event_time_max', 'event_time_min'],
    };

    const { data } = await new HttpClient().get(
        `dealers/${dealerId}/graph-api/offline-event-set`,
        params
    );

    return data;
};

export default getLastEventUploadDate;
