import getInitialData from './getInitialData';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const initialData = getInitialData();

export default {
    namespaced: true,
    state: {
        attribution: initialData.attribution,
        campaigns: initialData.campaigns,
        campaignsSelected: initialData.campaignsSelected,
        campaignsLoading: false,
        offlineEvents: [],
        offlineEventsLoading: false,
        offlineEventsCurrent: null,
        offlineEventsCurrentLoading: false,
        offlineEventsError: null,
        lastEventUploadDate: null,
        dealerInsightsError: null,
        metricsTotals: null,
        metricsTotalsLoading: false,
        metricsIntervals: [],
        metricsIntervalsLoading: false,
        metricsCampaigns: [],
        metricsCampaignsLoading: false,
        metricsCampaignsComparison: [],
        metricsCampaignsComparisonLoading: false,
        metricsInventory: [],
        metricsInventoryLoading: false,
        metricsTotalsComparison: null,
        metricsTotalsComparisonLoading: false,
        metricsTotalsPrevious90Days: null,
        metricsTotalsPrevious90DaysLoading: false,
        metricsTotalsPreviousMonth: null,
        metricsTotalsPreviousMonthLoading: false,
    },
    getters,
    actions,
    mutations,
};
