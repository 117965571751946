import formatInsightsData from '@/apis/facebook/formatInsightsData';
import { differenceInDays } from 'date-fns';
import { get } from 'lodash';
import { FACEBOOK_OFFLINE_EVENT_TYPES } from '@/helpers/globals';

const attributionWindows = [
    '1d_view',
    '1d_click',
    '7d_view',
    '7d_click',
    '28d_view',
    '28d_click',
];

const metricsTotalsFormatted = (state, getters, rootState, rootGetters) => {
    if (state.metricsTotals == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotals, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows,
    });
};

const metricsTotalsPreviousMonthFormatted = (
    state,
    getters,
    rootState,
    rootGetters
) => {
    if (state.metricsTotalsPreviousMonth == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotalsPreviousMonth, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows,
    });
};

const metricsTotalsPrevious90DaysFormatted = (
    state,
    getters,
    rootState,
    rootGetters
) => {
    if (state.metricsTotalsPrevious90Days == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotalsPrevious90Days, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows,
    });
};

const metricsIntervalsFormatted = (state, getters, rootState, rootGetters) => {
    if (!state.metricsIntervals.length) {
        return [];
    }

    const formatter = (data) =>
        formatInsightsData(data, {
            spendOverride: rootGetters['metrics/spendOverride'],
            attribution: state.attribution,
            attributionWindows,
        });

    return state.metricsIntervals.map(formatter);
};

const metricsCampaignsFormatted = (state, getters, rootState, rootGetters) => {
    if (!state.metricsCampaigns.length) {
        return [];
    }

    const formatter = (data) =>
        formatInsightsData(data, {
            spendOverride: rootGetters['metrics/spendOverride'],
            attribution: state.attribution,
            attributionWindows,
        });

    return state.metricsCampaigns.map(formatter);
};

const metricsCampaignsComparisonFormatted = (
    state,
    getters,
    rootState,
    rootGetters
) => {
    if (!state.metricsCampaignsComparison.length) {
        return [];
    }

    const formatter = (data) =>
        formatInsightsData(data, {
            spendOverride: rootGetters['metrics/spendOverride'],
            attribution: state.attribution,
            attributionWindows,
        });

    return state.metricsCampaignsComparison.map(formatter);
};

const metricsInventoryFormatted = (state) => {
    if (!state.metricsInventory.length) {
        return [];
    }

    const formatter = (vehicle) => {
        const keys = Object.keys(vehicle);
        keys.forEach((key) => {
            if (!(vehicle[key] instanceof Object)) {
                vehicle[key] = {
                    format: 'raw',
                    formatted: vehicle[key],
                    value: vehicle[key],
                };
            }
        });
        return vehicle;
    };

    return state.metricsInventory.map(formatter);
};

const metricsTotalsComparisonFormatted = (
    state,
    getters,
    rootState,
    rootGetters
) => {
    if (state.metricsTotalsComparison == null) {
        return null;
    }

    return formatInsightsData(state.metricsTotalsComparison, {
        spendOverride: rootGetters['metrics/spendOverride'],
        attribution: state.attribution,
        attributionWindows,
    });
};

/**
 * Determines if the current dealer is eligible to retrieve insights
 *
 * @returns Boolean
 */
const eligibleForMetrics = (state, getters, rootState, rootGetters) => {
    return (
        rootGetters.dealerHasFeatures(['facebook_ad_account']) &&
        !!rootGetters.dealerFacebookAdAccount
    );
};

/**
 * Determines if metrics are available
 *
 * @returns Boolean
 */
const hasMetrics = (state) => {
    return state.metricsTotals !== null;
};

const offlineEventsByType = ({ metricsTotals, attribution }) => {
    if (!Object.keys(metricsTotals).length) return {};

    const offlineEvents = metricsTotals.actions.filter((action) =>
        action?.action_type.includes('offline_conversion')
    );

    const offlineEventsByType = {};
    FACEBOOK_OFFLINE_EVENT_TYPES.forEach((eventType) => {
        const offlineEvent = offlineEvents.find((e) => e.action_type === `offline_conversion.${eventType.key}`);

        if (offlineEvent) {
            const viewWindow = get(attribution, 'offline.view');
            const clickWindow = get(attribution, 'offline.click');

            const viewValue = get(offlineEvent, `${viewWindow}_view`, 0);
            const clickValue = get(offlineEvent, `${clickWindow}_click`, 0);

            offlineEventsByType[eventType.key] = Number(viewValue) + Number(clickValue);
        }
    });

    return offlineEventsByType;
};

const totalOfflineEvents = (state, getters) => {
    return Object.values(getters.offlineEventsByType).reduce((acc, cur) => acc + cur, 0);
};

/**
 * The total loaded offline events
 */
const totalCurrentOfflinePurchaseEvents = ({
    metricsTotalsPrevious90Days,
    attribution,
},) => {
    if (metricsTotalsPrevious90Days === null) return 0;

    const offlineEventsPurchase = metricsTotalsPrevious90Days.actions.find(
        (action) => action?.action_type === 'offline_conversion.purchase'
    );

    const viewWindow = get(attribution, 'offline.view');
    const clickWindow = get(attribution, 'offline.click');

    const viewValue = get(offlineEventsPurchase, `${viewWindow}_view`, 0);
    const clickValue = get(offlineEventsPurchase, `${clickWindow}_click`, 0);

    return Number(viewValue) + Number(clickValue);
};

/**
 * The total loaded offline events
 */
const totalOfflinePurchaseEvents = ({ metricsTotals, attribution }, getters) => {
    if (!getters.hasMetrics) return 0;

    const offlineEventsPurchase = metricsTotals.actions.find(
        (action) => action?.action_type === 'offline_conversion.purchase'
    );

    const viewWindow = get(attribution, 'offline.view');
    const clickWindow = get(attribution, 'offline.click');

    const viewValue = get(offlineEventsPurchase, `${viewWindow}_view`, 0);
    const clickValue = get(offlineEventsPurchase, `${clickWindow}_click`, 0);

    return Number(viewValue) + Number(clickValue);
};

const canViewOfflinePurchaseAttribution = (state, getters, rootState) => {
    if (!state.lastEventUploadDate) {
        return false;
    }

    const diff = differenceInDays(
        state.lastEventUploadDate,
        rootState.metrics.dateRange.endDate
    );

    return diff <= 0;
};

/**
 * Formats local storage data
 */
const storage = (state) => {
    return {
        campaigns: state.campaigns,
        campaignsSelected: state.campaignsSelected,
    };
};

/**
 * Determines if any tracked metrics are loading
 */
const metricsLoading = (state) => {
    return (
        state.campaignsLoading ||
        state.offlineEventsLoading ||
        state.offlineEventsCurrentLoading ||
        state.metricsTotalsLoading ||
        state.metricsIntervalsLoading ||
        state.metricsCampaignsLoading ||
        state.metricsCampaignsComparisonLoading ||
        state.metricsTotalsComparisonLoading ||
        state.metricsTotalsPreviousMonthLoading ||
        state.metricsTotalsPrevious90DaysLoading
    );
};

export default {
    storage,
    eligibleForMetrics,
    hasMetrics,
    offlineEventsByType,
    totalOfflineEvents,
    totalCurrentOfflinePurchaseEvents,
    totalOfflinePurchaseEvents,
    canViewOfflinePurchaseAttribution,
    metricsTotalsFormatted,
    metricsTotalsPreviousMonthFormatted,
    metricsTotalsPrevious90DaysFormatted,
    metricsIntervalsFormatted,
    metricsCampaignsFormatted,
    metricsCampaignsComparisonFormatted,
    metricsInventoryFormatted,
    metricsTotalsComparisonFormatted,
    metricsLoading,
};
